var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ar-main-container',{key:_vm.$route.params.id},[_c('header',{staticClass:"layout_header"},[_c('h2',{staticClass:"layout_header-title card__title"},[_vm._v(" "+_vm._s(_vm.currentProject.name)+" ")])]),_c('div',{staticClass:"stages"},[_vm._l((_vm.projectCard.stages),function(stage){return [(stage.children.length)?_c('div',{key:stage.name,staticClass:"stage"},[_c('div',{staticClass:"stage__name"},[_vm._v(" "+_vm._s(stage.name)+" ")]),_c('div',{staticClass:"stage__list"},[_vm._l((stage.children),function(item,index){return [(index < stage.show)?_c('div',{key:'A'+index,staticClass:"stage__list_item"},[(item.type === 'single')?[_c('router-link',{staticClass:"stage__card",attrs:{"to":{
                                    name: 'documents-id',
                                    params: {
                                        id: item.doc.id,
                                    },
                                },"tag":"a"},domProps:{"innerHTML":_vm._s(item.doc.title)}}),(item.doc.document && item.doc.document.publicUrl)?_c('a',{staticClass:"file__load",attrs:{"target":"_blank","href":'https://api.lifestyle.aequo.ua'+item.doc.document.publicUrl}},[_c('div',{staticClass:"file__icon"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M11.6141 15.3932C11.8517 15.5357 12.1484 15.5357 12.3859 15.3932L17.3859 12.3932L18.029 12.0074L17.2573 10.7211L16.6141 11.107L12.75 13.4255V6V5.25H11.25V6V13.4255L7.38589 11.107L6.74277 10.7211L5.97102 12.0074L6.61414 12.3932L11.6141 15.3932ZM9 17.25L8.25 17.25L8.25 18.75L9 18.75L15 18.75H15.75V17.25H15L9 17.25Z","fill":"#4297E7"}})]),_vm._v(" Скачати ")]),_c('div',{staticClass:"file__lan"},[_vm._v(_vm._s(item.doc.lang._label_))])]):_vm._e()]:[(item.children.length)?[_c('div',{staticClass:"stage__group"},[_c('div',{staticClass:"stage__group_name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_vm._l((item.children),function(child,index){return [_c('div',{key:'B'+index,staticClass:"stage__list_item"},[(index < item.show)?_c('router-link',{key:'C'+ index,staticClass:"stage__card",attrs:{"to":{
                                                        name: 'documents-id',
                                                        params: {
                                                            id: child.doc.id,
                                                        },
                                                    },"tag":"a"},domProps:{"innerHTML":_vm._s(child.doc.title)}}):_vm._e(),(child.doc.document && child.doc.document.publicUrl)?_c('a',{staticClass:"file__load",attrs:{"target":"_blank","href":'https://api.lifestyle.aequo.ua'+child.doc.document.publicUrl}},[_c('div',{staticClass:"file__icon"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M11.6141 15.3932C11.8517 15.5357 12.1484 15.5357 12.3859 15.3932L17.3859 12.3932L18.029 12.0074L17.2573 10.7211L16.6141 11.107L12.75 13.4255V6V5.25H11.25V6V13.4255L7.38589 11.107L6.74277 10.7211L5.97102 12.0074L6.61414 12.3932L11.6141 15.3932ZM9 17.25L8.25 17.25L8.25 18.75L9 18.75L15 18.75H15.75V17.25H15L9 17.25Z","fill":"#4297E7"}})]),_vm._v(" Скачати ")]),_c('div',{staticClass:"file__lan"},[_vm._v(_vm._s(child.doc.lang._label_))])]):_vm._e()],1)]}),(item.children.length > 5 && item.children.length >= item.show)?_c('div',{staticClass:"show__more",on:{"click":function($event){item.show += 5}}},[_vm._v(" Show more ")]):_vm._e()],2)]:_vm._e()]],2):_vm._e()]}),(stage.children.length > 5 && stage.children.length >= stage.show)?_c('div',{staticClass:"show__more",on:{"click":function($event){stage.show += 5}}},[_vm._v(" Show more ")]):_vm._e()],2)]):_vm._e()]})],2),(_vm.projectCard.additionalMaterials)?_c('div',{staticClass:"additional__docs"},[_c('div',{staticClass:"additional__docs_title"},[_vm._v("ДОПОМІЖНІ МАТЕРІАЛИ")]),_c('div',{staticClass:"additional__docs_list"},_vm._l((_vm.projectCard.additionalMaterials),function(doc,index){return _c('router-link',{key:'D'+ index,staticClass:"stage__card",attrs:{"to":{
                name: 'documents-id',
                params: {
                    id: doc.doc.id,
                },
            },"tag":"a"},domProps:{"innerHTML":_vm._s(doc.doc.title)}})}),1)]):_vm._e(),(_vm.projectCard.literature)?_c('div',{staticClass:"additional__docs"},[_c('div',{staticClass:"additional__docs_title"},[_vm._v("ЛІТЕРАТУРА")]),_c('div',{staticClass:"additional__docs_list"},_vm._l((_vm.projectCard.literature),function(doc,index){return _c('router-link',{key:'E'+ index,staticClass:"stage__card",attrs:{"to":{
                name: 'documents-id',
                params: {
                    id: doc.doc.id,
                },
            },"tag":"a"},domProps:{"innerHTML":_vm._s(doc.doc.title)}})}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }