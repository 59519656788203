






























































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import ArMainContainer from '@/components/section/ar-main-container.vue';
import ArMainSidebar from '@/components/section/ar-main-sidebar.vue';

import ArButton from '@/components/form/ar-button.vue';
import ArSearch from '@/components/form/ar-search.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';
import ArSelectSort from '@/components/form/ar-select-sort.vue';

import MainFilters from '@/components/block/main-filters.vue';
import FullDocs from '@/components/block/full-docs.vue';
import BookCards from '@/components/block/book-cards.vue';
import SearchResultEmpty from '@/components/block/search-result-empty.vue';

import projectById from '@/graphql/projects/projecById.graphql';
import documentsByMaterialAndProject from '@/graphql/types/documentsByMaterialAndProject.graphql'; 
import {
    defaultProject,
    Project,
    ProjectDocsByMaterial,
    SingleProjectResp,
} from '@/types/requests/projects';
import { clone } from '@/helpers/objects';
import graphql from '@/plugins/graphql'; 
import BooksSection from '@/components/block/books-section.vue';
import { Action, Getter } from 'vuex-class';
import { WhereIdObject } from '@/types/requests/filters'; 
import { ApiDocumentMaterial } from '@/types/requests/documentMaterial';

Component.registerHooks(['beforeRouteLeave']);
@Component({
    name: 'ProjectCardsProject',
    components: {
        BooksSection,
        ArMainContainer,
        ArMainSidebar,
        ArButton,
        ArSearch,
        ArDownloadMore,
        ArSelectSort,
        MainFilters,
        SearchResultEmpty,
        FullDocs,
        BookCards,
    },
})
export default class ProjectCardsProject extends Vue {
    private currentProject: Project = clone(defaultProject);
    private docsByType: ApiDocumentMaterial[] = [];
    private perPage = 5;
    public projectCard: any = {}
 
 

    @Action('Filters/CLEAR_SELECTED_FILTERS')
    CLEAR_SELECTED_FILTERS: () => void;
    @Action('Search/CLEAR_SEARCH_DATA')
    CLEAR_SEARCH_DATA: () => void;
    @Action('Sort/CLEAR_SORT_DATA')
    CLEAR_SORT_DATA: () => void;

    @Action('Filters/LOAD_CURRENT_PROJECT_ID')
    LOAD_CURRENT_PROJECT_ID: (payload: WhereIdObject | undefined) => void;
    @Action('SSF/LOAD_SSF_RESULT')
    public LOAD_SSF_RESULT!: (payload: {
        isBook: boolean;
        isLoadMore: boolean;
        searchedListPropName?: string;
    }) => Promise<void>;
    // endregion

    // region WATCHERS
    @Watch('SELECTED_FILTERS', { deep: true })
    async onFiltersChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }
    @Watch('SEARCH_VALUE')
    async onSearchValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }

    @Watch('SORT_VALUE')
    async onSortValueChanged(): Promise<void> {
        await this.LOAD_SSF_RESULT({ isBook: false, isLoadMore: false });
    }
    // endregion
 
    // endregion
 
    private async loadProject(): Promise<void> {
        const projectId = this.$route.params.id as string;
        const projectPromise = graphql<SingleProjectResp>(projectById, {
            id: projectId,
        });
        const projectDocsPromise = graphql<ProjectDocsByMaterial>(
            documentsByMaterialAndProject,
            {
                projectId,
                perPage: this.perPage,
            }
        );
        const resp: [
            SingleProjectResp | undefined,
            ProjectDocsByMaterial | undefined
        ] = await Promise.all([projectPromise, projectDocsPromise]);
        const pResp = resp[0]; 
        if (pResp && pResp.Project) {
            this.currentProject = pResp.Project;
        } 
    }
    // endregion

    // region LIFECYCLE HOOKS
    async created(): Promise<void> {
        console.log('Created')
        await this.loadProject();
        this.loadProjectCards();
        this.LOAD_CURRENT_PROJECT_ID({ id: this.$route.params.id });
    }


    public async loadProjectCards() {
        const response = await fetch('https://stages.aequolab.aequo.ua/project-stage/'+ this.$route.params.id);
        // const response = await fetch('http://localhost:3000/project-stage/'+ this.$route.params.id);
        this.projectCard = await response.json();
        console.log({response: this.projectCard })
    }
 
    // endregion
}
